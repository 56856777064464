import Vue from 'vue'
import axios from 'axios';
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/flexible'
import './plugins/element.js'
// 导入全局样式表
import './assets/css/global.css'
import VueQuillEditor  from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// 实现quill-editor编辑器拖拽上传图片
import Quill from 'quill'
import { ImageDrop } from "quill-image-drop-module"
Quill.register('modules/imageDrop', ImageDrop)

// 实现quill-editor编辑器调整图片尺寸
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', ImageResize)
Vue.use(VueQuillEditor);

import http from './http';  //此处问http文件的路径
Vue.prototype.$http = http;
//import md5 from 'js-md5';
//Vue.prototype.$md5 = md5;
import crypto from '@/utils/crypto.js';
Vue.prototype.crypto = crypto;
import  Base64  from "js-base64";
Vue.prototype.Base64 = Base64;
// import ElementUI from 'element-ui'
 
// import Message from '@/utils/resetMessage.js'
 
// Vue.use(ElementUI)
 
// Vue.prototype.$message=Message 
//Vue.prototype.$resetMessage = Message

if (process.env.NODE_ENV !== 'development') {
  // process是Node环境全部变量, 运行时根据敲击的命令不同, 脚手架会取环境变量给env添加属性和值
 // console.log = function () {}
 // console.error = function () {}
  console.dir = function () {}
}

Vue.config.productionTip = true

// 上报错误日志
const errors = []
const getTimestamp = () => {
  return new Date()
}

//
Vue.config.errorHandler = error => {
  //errors.push({time:getTimestamp(),content:error.stack})
  console.log("time:"+getTimestamp(),error.stack)
  let ip=localStorage.getItem("ip")
  axios.get(ip+'/api/bug_report?error='+error)
  .then(response => {})
}
//JavaScript的一个全局事件处理函数，当JavaScript代码抛出异常失败时，它就会被调用。
//通过在全局范围内捕获错误并记录它们，有助于监视应用程序的健康状况和用户发现问题（bug）
window.onerror = (message,source,lineno,colon,error) => {
 // errors.push({time:getTimestamp(),content:error.stack})
  console.log("time:"+getTimestamp(),error.stack)
  let ip=localStorage.getItem("ip")
  axios.get(ip+'/api/bug_report?error='+error)
  .then(response => {})
}
//JavaScript中有很多内置事件，例如，鼠标单击事件、键盘按键事件、页面滚动事件等，
//我们可以通过事件监听来捕捉这些事件以便在相应的时间作出相应的响应。
window.addEventListener('error',event => {
  //errors.push({time:getTimestamp(),content:event.error.stack})
  console.log("time:"+getTimestamp(),event.error.stack)
  let ip=localStorage.getItem("ip")
  axios.get(ip+'/api/bug_report?error='+event.error)
  .then(response => {})
})

// setInterval(()=>{
//   if(errors.length > 0){
//     console.log(errors)
//     const length = errors.length
//     this.axios.post(
//       '/',errors
//     ).then(response => {
//       errors.splice(0,length)
//     },error => {
//       console.log('保存日志报错了',res)
//     })

//   }
// },3000)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
