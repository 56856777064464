import Vue from 'vue'
import VueRouter from 'vue-router'
// 加载loding效果
import NProgress from "nprogress"
import "nprogress/nprogress.css"
NProgress.configure({ showSpinner: false })
// 导入封装的判断是否为移动端设备的函数
import { isMobile } from '@/utils/mobileORpc'
import { myBrowser } from '@/utils/browserType'
import { getToken } from '@/utils/token'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/paper_guide',
    component: () => import('../views/Manual.vue'),      
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/home/generate',
    component: () => import('../views/Home.vue'),
    children: [  
      {
        path: 'trans',
        name: '4',
        component: () => import('../views/Trans.vue'),      
      },
      {
        path: 'chatgpt',
        name: '2',
        component: () => import('../views/GPT.vue'),      
      },
      {
        path: 'help',
        name: 'help',
        component: () => import('../views/Help.vue'),
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('../views/About.vue'),
      },
      {
        path: 'drawing',
        name: '3',
        component: () => import( '../views/Inviting.vue'),
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('../views/Account.vue'),
      },
      {
        path: 'generate',
        name: '1',
        component: () => import('../views/Generate.vue'),
      },
      {
        path: 'translation',
        name: '5',
        component: () => import('../views/Translation.vue'),
      },      
     

    ]
  },
  {
    path: '*',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

// 路由前置全局守卫（在路由发生真正跳转之前，会执行该函数）
router.beforeEach((to, from, next) => {
  // 如果是移动端访问，阻止继续访问
  if (isMobile()) {
      console.log(myBrowser())
      NProgress.start()
      next()
    // alert('检测到当前设备为移动端，因暂未做移动端适配，将无法继续访问，请用电脑（推荐谷歌浏览器）访问！谢谢！')
    // return

  } else {
    console.log(myBrowser())
    NProgress.start()
    next()
    // if (myBrowser() === 'Chrome') {
    //   console.log(myBrowser())
    //   NProgress.start()
    //   next()
    // } else {
    //   console.log(myBrowser())
    //   alert('检测到当前浏览器不是谷歌内核浏览器，将无法继续访问，强烈推荐使用谷歌浏览器！请使用谷歌浏览器访问，谢谢理解与支持！')
    //   return
    // }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
