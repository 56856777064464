import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
 // 全局变量
 state: {
  username: ''
},
// 修改全局变量必须通过mutations中的方法
  // mutations只能采用同步方法
  mutations: {
    setusername(state,username){
      state.username=username
    }

  },
  // 异步方法用actions
  // actions不能直接修改全局变量，需要调用commit方法来触发mutation中的方法
  actions: {
    getUser(context, username){
      context.commit('setusername',username)
    },
  },
  getters: {
    getusername(state){  //承载变化的对象（username）的值
      return state.username
    },
  },
  modules: {
  }
})
